﻿.single_post {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;

    .body{
        padding: 30px;
    }

    .img-post {
        position: relative;
        overflow: hidden;
        max-height: 500px;
        margin-bottom: 30px;

        > img {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            opacity: 1;
            -webkit-transition: -webkit-transform .4s ease, opacity .4s ease;
            transition: transform .4s ease, opacity .4s ease;
            max-width: 100%;
            filter: none;
            -webkit-filter: grayscale(0);
            -webkit-transform: scale(1.01);
        }

        &:hover{

            img{
                -webkit-transform: scale(1.02);
                -ms-transform: scale(1.02);
                transform: scale(1.02);
                opacity: .7;
                filter: gray; /* IE5+ */
                -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
                -webkit-transition: all .8s ease-in-out;
            }

            .social_share{
                display: block;
            }
        }
    }

    .footer{
        padding: 0 30px 30px 30px;
        .actions{
            display: inline-block;
        }

        .stats{
            cursor: default;
            list-style: none;
            padding: 0;
            display: inline-block;
            float: right;
            margin: 0;
            line-height: 35px;

            li{
                border-left: solid 1px rgba(160, 160, 160, 0.3);
                display: inline-block;
                font-weight: 400;
                letter-spacing: 0.25em;
                line-height: 1;
                margin: 0 0 0 2em;
                padding: 0 0 0 2em;
                text-transform: uppercase;
                font-size: 13px;
                a{
                    color: #777;
                }

                &:first-child{
                    border-left: 0;
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }
    }

    h3 {
        font-size: 20px;
        text-transform: uppercase;

        a {
            color: #242424;
            text-decoration: none;
        }
    }

    p{
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        margin: 0;
    }

    .blockquote{
        p {
            margin-top: 0 !important;
        }
    }

    .meta{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            display: inline-block;
            margin-right: 15px;
            a{
                font-style: italic;
                color: #959595;
                text-decoration: none;
                font-size: 12px;
                i{
                    margin-right: 6px;
                    font-size: 12px;
                }
            }
        }
    }
}

.single_post2{
    overflow: hidden;

    .content{
        margin-top:15px;
        margin-bottom:15px;
        padding-left: 80px;
        position: relative;

        .actions_sidebar{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 60px;
            a{
                display: inline-block;
                width: 100%;
                height: 60px;
                line-height: 60px;
                margin-right: 0;
                text-align: center;
                border-right: 1px solid #e4eaec;
            }
        }

        .title{
            font-weight: 100;
        }
        .text{
            font-size: 15px;
        }
    }
}

.right-box {

    .categories-clouds{
        li {
            display: inline-block;
            margin-bottom: 5px;
            a{
                display: block;
                border: 1px solid;
                padding: 6px 10px;
                border-radius: 3px;
            }
        }
    }

    .instagram-plugin{
        overflow: hidden;
        li {
            float: left;
            overflow: hidden;
            border: 1px solid #fff;
        }
    }
}
.instagram-plugin{
    overflow: hidden;
    li {
        float: left;
        overflow: hidden;
        border: 1px solid #fff;
    }
}

.comment-reply{

    li{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: none;
        }
        h5{
            font-size: 18px;
        }
        p{
            margin-bottom: 0px;
            font-size: 15px;
            color: #777;
        }
    }

    .list-inline{
        li{
            display: inline-block;
            margin: 0;
            padding-right: 20px;
            a{
                font-size: 13px;
            }
        }
    }

}


/*  end Blog page*/
@media all and (min-width: 1200px) and (max-width: 1500px) {

}

@media (max-width: 640px) {
    .blog-page{

        .left-box {

            .single-comment-box{
                > ul{
                    > li {
                        padding: 25px 0;
                    }
                }

                ul{

                    li{
                        .icon-box {
                            display: inline-block;
                        }

                        .text-box {
                            display: block;
                            padding-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .single_post{

            .footer .stats{
                float: none;
                margin-top: 10px;
            }

            .body,
            .footer{
                padding: 30px;
            }
        }
    }
}

.categories-clouds li{display:inline-block;margin-bottom:5px; margin-right:5px;}
.categories-clouds li a{display:block;border:1px solid;padding:6px 10px;border-radius:3px}
